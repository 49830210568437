<i18n locale="th" lang="yaml" >

page.title : "Privacy Policy"
privacy.policy : " <div style='font-size: 1.1rem'> <h1 style='text-align: center'>นโยบายข้อมูลส่วนบุคคล</h1><br>

<p style='text-align: left'><b>บริษัท ไฮฟ์กราวนด์ จำกัด</b> (<b>“บริษัท”</b>) ขอแจ้งให้ท่านที่ติดต่อมายังบริษัททราบว่า บริษัทมีความจำเป็นต้องเก็บ รวบรวม และใช้ข้อมูลส่วนบุคคลของท่านที่ติดต่อมาใช้บริการของบริษัท ภายใต้นโยบายข้อมูลส่วนบุคคลฉบับนี้ และเมื่อท่านติดต่อมายังบริษัทและได้เปิดเเผยข้อมูลส่วนบุคคลของท่านให้แก่บริษัท บริษัทจะถือว่าท่านตกลงและยอมรับที่จะปฏิบัติตามนโยบายข้อมูลส่วนบุคคลฉบับนี้แล้ว เพื่อประโยชน์ในการติดต่อ ประสานงาน และ/หรือการให้บริการจากบริษัทแก่ท่าน <br><br>

<b style='text-decoration: underline'>ข้อมูลส่วนบุคคลที่บริษัทประมวลผล</b><br><br>บริษัทอาจได้รับข้อมูลส่วนบุคคลของท่าน ได้จากหลายช่องทาง ดังนี้  <br>
(1) ได้รับโดยตรง จากการติดต่อสื่อสารระหว่างบริษัทและท่าน <br>
(2) ได้รับทางอ้อมจากการอ้างอิงจากบุคคลอื่น ซึ่งท่านอาจให้ความยินยอมให้บุคคลดังกล่าวเปิดเผยส่งต่อข้อ มูลส่วนบุคคลของท่านให้แก่ บริษัท หรือ <br>
(3) เก็บรวบรวมโดยอัตโนมัติด้วยระบบ เมื่อท่านเข้ามาเยี่ยมชมเว็บไซต์ และ/หรือใช้บริการต่าง ๆ ของบริษัท <br><br>

ข้อมูลส่วนบุคคลที่บริษัทมีความจำเป็นต้องเก็บ รวบรวม ใช้ และประมวลผลภายใต้นโยบายข้อมูลส่วนบุคคล ฉบับนี้ ได้แก่ <br>
(1) ชื่อนามสกุล รวมถึงข้อมูลที่เกี่ยวเนื่องของท่าน หรือของตัวแทนของท่าน (ในกรณีที่ท่านติดต่อมาในนามของนิติบุคคล) ซึ่งอาจรวมถึงข้อมูลเอกสารการแสดงตนของท่าน <br>
(2) ข้อมูลการติดต่อ อาทิ เบอร์โทรศัพท์ อีเมล หรือข้อมูล Social Media Account <br>
(3) ข้อมูลส่วนบุคคลอื่น ๆ  ที่ท่านอาจให้แก่บริษัทระหว่างการติดต่อสื่อสาร เช่น ข้อมูลเรื่องที่ต้องการติดต่อสอบถาม ข้อมูลความสนใจ หรือข้อมูลประกอบอื่น ๆ ที่อาจระบุตัวตนของท่านได้ซึ่งท่านให้แก่ บริษัท โดยตรงผ่านช่องทางการติดต่อสื่อสารต่าง ๆ  <br>
(4) กรณีการติดต่อผ่าน Website อาจรวมถึงข้อมูลทางเทคนิคของท่าน ได้แก่  IP Address, Cookies รวมถึงข้อมูลพฤติกรรมการสืบค้นของท่าน <br><br>

<b style='text-decoration: underline'>วัตถุประสงค์ในการเก็บรวบรวมและใช้ข้อมูลส่วนบุคคล </b> <br><br>
บริษัทจำเป็นต้องเก็บ รวบรวม และใช้ข้อมูลส่วนบุคคลของท่านตามที่ระบุไว้ภายใต้นโยบายข้อมูลส่วนบุคคลฉบับนี้ เพื่อวัตถุประสงค์ต่าง ๆ  ดังต่อไปนี้ <br>
(1) เพื่อการจัดการตอบรับการสื่อสารที่ท่านติดต่อมายังบริษัท เช่น เพื่อการตอบคำถาม การส่งข้อมูลที่เกี่ยวข้องตามที่ท่านร้องขอและต้องการ การจัดการข้อร้องเรียน หรือการตอบรับความคิดเห็นต่าง ๆ ที่ท่านให้แก่บริษัทโดยตรง ผ่านช่องทางการติดต่อสื่อสารต่าง ๆ รวมถึงการประสานงานต่อเนื่องไปจนถึงการให้บริการ การจัดทำสัญญาการให้บริการ และ/หรือการปฏิบัติสิทธิและหน้าที่ที่บริษัทและท่านอาจตกลงและจัดทำระหว่างกันต่อเนื่อง <br>
(2) เพื่อการปฏิบัติหน้าที่ตามกฎหมายที่บริษัทอาจอยู่ภายใต้เงื่อนไขทีต้องปฏิบัติตาม ซึ่งอาจรวมถึงแต่ไม่จำกัดเพียง การปฏิบัติหน้าที่ในการจัดทำเอกสารบัญชี และภาษีสำหรับการให้บริการใด ๆ ที่บริษัทอาจให้ แก่ท่าน <br>
(3) เพื่อการสร้างและปรับปรุงความสัมพันธ์ทางธุรกิจ รวมถึงการปรับปรุงการให้บริการที่บริษัทจะดำเนินการเพื่อประโยชน์ของท่านให้ดีขึ้น ซึ่งบริิษัทอาจรวบรวม ใช้ข้อมูลส่วนบุคคลของท่าน เพื่อประ โยชน์ในการควบคุม การรับประกันคุณภาพ การวิเคราะห์การบริหารจัดการและแก้ไขปัญหาต่าง ๆ  ที่เกี่ยวข้องของบริษัท รวมถึงแต่ไม่จำกัดเพียงการฝึกอบรมพนักงาน หรือการวางแผนการให้ปรับปรุงการให้บริการในอนาคต <br>
(4) เพื่อประโยชน์ในการปกป้องและต่อสู้สิทธิอันชอบด้วยกฎหมายของบริษัท ในกรณีที่อาจเกิดข้อพิพาท ระหว่างท่านและบริษัท ไม่ว่าในลักษณะใด และ <br>
(5) กรณีที่บริษัทอาจได้รับความยินยอม ในการประมวลผลข้อมูลส่วนบุคคลด้วยจุดประสงค์เฉพาะเจาะจงจากท่าน เช่น เพื่อการติดต่อประชาสัมพันธ์ทางการตลาด และการประชาสัมพันธ์ข่าวสารต่าง ๆ บริษัทจะประมวลผลข้อมูลตามวัตถุประสงค์ดังกล่าว <br><br>

<b style='text-decoration: underline'>ระยะเวลาในการประมวลผลข้อมูลส่วนบุคคล </b> <br><br>
เพื่อดำเนินการตามวัตถุประสงค์ที่ระบุไว้ข้างต้นทั้งหมด บริษัทจำเป็นต้องเก็บรวบรวม และประมวลผลข้อมูลส่วนบุคคลของท่านเป็นระยะเวลา ดังนี้ <br>
(1) สำหรับการประมวลผลข้อมูลด้วยวัตถุประสงค์การให้บริการ การจัดทำสัญญา และ/หรือการปฏิบัติสิทธิและหน้าที่ภายใต้สัญญา บริษัทจำเป็นต้องประมวลผลข้อมูลส่วนบุคคลของท่าน ตราบเท่าที่บริษัทยังมีหน้าที่ให้บริการแก่ท่าน <br>
(2) สำหรับการประมวลผลข้อมูล ด้วยวัตถุประสงค์การปฏิบัติหน้าที่ตามกฎหมายของบริษัท บริษัทจำเป็นต้องประมวลผลข้อมูลส่วนบุคคลของท่าน ไว้ตามระยะเวลาที่กฎหมายที่เกี่ยวข้องกำหนดไว้ <br>
(3) สำหรับการประมวลผลข้อมูลส่วนบุคคลเพื่อการสร้างและปรับปรุงความสัมพันธ์ทางธุรกิจ และ/หรือการปรับปรุงการให้บริการ  บริษัทสงวนสิทธิ์ในการเก็บรักษาข้อมูลส่วนบุคคลนั้นเท่าที่บริษัทอาจ มีความจำเป็นทางธุรกิจ โดยรับประกันไม่ให้กระทบสิทธิของท่านในฐานะเจ้าของข้อมูลเกินสมควร <br>
(4) สำหรับการประมวลผลข้อมูลส่วนบุคคล เพื่อการปกป้องและต่อสู้สิทธิอันชอบด้วยกฎหมายของบริษัท บริษัทมีความจำเป็นเก็บรักษาข้อมูลส่วนบุคคลดังกล่าว ตลอดอายุความที่เกี่ยวข้อง และ <br>
(5) กรณีที่ท่านให้ความยินยอมแก่บริษัท ในการประมวลผลข้อมูลด้วยวัตถุประสงค์เฉพาะ บริษัทจะประมวล ผลข้อมูลส่วนบุคคลของท่านจนกว่าท่้านจะถอนความยินยอม <br><br>

<b style='text-decoration: underline'>การเปิดเผยข้อมูลส่วนบุคคล</b> <br><br>
โดยหลักการแล้ว ข้อมูลส่วนบุคคลของท่านจะไม่ถูกเปิดเผย ยกเว้นเป็นการเปิดเผยข้อมูลส่วนบุคคลที่บริษัทจำเป็นต้องดำเนินการ ให้แก่ กลุ่มบุคคลดังนี้  <br>
(1) การเปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่ ผู้ให้บริการภายนอกของบริษัท ที่ให้การช่วยเหลือสนับสนุนบริษัทในการให้บริการแก่ท่าน รวมถึงที่ปรึกษาด้านการดำเนินธุรกิจของบริษัท โดยบริษัทจะส่งต่อและเปิดเผยข้อมูลส่วนบุคคลของท่านตามขอบเขตของวัตถุประสงค์ที่ระบุไว้ และบนพื้นฐานเท่าที่จำเป็นเท่านั้น หรือ  <br>
(2) กรณีที่บริษัทอาจอยู่ภายใต้บังคับคำสั่งหรือคำพิพากษา ของหน่วยงานราชการ บริษัทอาจมีความจำเป็นในการส่งต่อเปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่หน่วยงานดังกล่าว <br><br>

<b style='text-decoration: underline'>บริษัทรับประกันการจัดให้มีมาตรการการรักษาความมั่นคงปลอดภัยที่เหมาะสม </b> <br><br>
เพื่อป้องกันการเข้าถึง การใช้ การเปลี่ยนแปลง การแก้ไข หรือการเปิดเผยข้อมูลส่วนบุคคลโดยปราศจากอำนาจหรือโดยมิชอบ ทั้งนี้ บริษัทจะจัดให้มีการทบทวนมาตรการดังกล่าวเป็นระยะตามความเหมาะสม และโดยสอดคล้องกับกฎหมายที่เกี่ยวข้อง  <br><br>

<b style='text-decoration: underline'>สิทธิของเจ้าของข้อมูล </b> <br><br>
บริษัทเคารพสิทธิของท่าน ในฐานะเจ้าของข้อมูลภายใต้กฎหมายที่เกี่ยวข้อง โดยท่านสามารถติดต่อบริษัทเพื่อขอใช้สิทธิของท่าน ซึ่งได้แก่   <br>
(1) สิทธิในการถอนความยินยอม <br>
(2) สิทธิในการขอเข้าถึง <br>
(3) สิทธิในการขอรับสำเนาข้อมูลส่วนบุคคลของท่าน <br>
(4) สิทธิในการขอปรับปรุงข้อมูลให้ถูกต้อง <br>
(5) สิทธิในการขอคัดค้านการประมวลผลข้อมูลส่วนบุคคล <br>
(6) สิทธิในการขอโอนถ่ายข้อมูลที่เก็บบันทึกในรูปแบบอิเล็กทรอนิกส์ที่สามารถถ่ายโอนได้ง่าย รวมถึงการโอนถ่ายไปให้แก่ผู้ควบคุมข้อมูลส่วนบุคคลอื่น <br>
(7) สิทธิขอให้ลบหรือทำลายข้อมูลของท่านเมื่อหมดความจำเป็น <br>
(8) สิทธิในการขอให้ระงับการประมวลผลข้อมูลส่วนบุคคลชั่วคราว <br>
(9) สิทธิในการร้องเรียนได้ <br><br>

<b style='text-decoration: underline'>ข้อมูลติดต่อบริษัท</b> <br><br>
เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล  <br>
ที่อยู่:บริษัท ไฮฟ์กราวนด์ จำกัด (สำนักงานใหญ่) - 93/49 ซอยเกิดทรัพย์, ถนนบางขุนนนท์, บางขุนนนท์, บางกอกน้อย, กทม, 10700<br>
อีเมล หรือเบอร์โทรศัพท์: info@hiveground.com / <a href='tel:6031112298'>021634654</a> <br><br>

<b style='text-decoration: underline'>รายละเอียดผู้ควบคุมข้อมูล</b> <br><br>
ชื่อ: บริษัท ไฮฟ์กราวนด์ จำกัด<br>
สถานที่ติดต่อ: บริษัท ไฮฟ์กราวนด์ จำกัด (สำนักงานใหญ่) - 93/49 ซอยเกิดทรัพย์, ถนนบางขุนนนท์, บางขุนนนท์, บางกอกน้อย, กทม, 10700<br>
ช่องทางการติดต่อ: <a href='tel:+66 (0)2-163-4654'>+66 (0)2-163-4654</a><br>
อีเมล: info@hiveground.com<br>
เว็บไซต์: <a href='https://www.hiveground.com' target='_blank'>https://www.hiveground.com</a><br><br>

<b style='text-decoration: underline'>รายละเอียดตัวแทนผู้รับผิดชอบ</b> <br><br>
ชื่อ: พสุ บุญวิสุทธิ์<br>
สถานที่ติดต่อ: บริษัท ไฮฟ์กราวนด์ จำกัด (สำนักงานใหญ่) - 1174/2-3 ถนนพระราม 4 แขวงคลองเตย เขตคลองเตย กรุงเทพมหานคร 10110<br>
ช่องทางการติดต่อ: <a href='tel:+66 (0)2-163-4654'>+66 (0)2-163-4654</a><br>
อีเมล: pasu.b@hiveground.com<br><br>

</p>
</div>"

</i18n>


<template>
	<div>
		<div v-html="$t('privacy.policy')"/>
	</div>

</template>


<script>

export default {
	components : {
	} ,
	page() {
		return {
			title: this.$t('page.title'),
		}
	},
	data() {
		return {

		}
	} ,
	computed : {

	} ,
	methods : {

	}

}

</script>
